import logo from './logo.svg';
import './App.css';
import PdfApi from "./apis/PdfApi";
import {useState} from "react";
import RestartBiddingDialog from "./components/RestartBiddingDialog/RestartBiddingDialog";
import {useParams} from "react-router-dom";

function App(props) {
    const [view, setView] = useState("default");
    const { id, key } = useParams();

    const exportPdf = async () => {
        const biddingData = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v2/bidding/biddingInfoAsAdmin/${id}/${key}`)
            .then(res => res.json());

        const pdfApi = new PdfApi();
        pdfApi.exportPdf(biddingData);
    };

    const restartBidding = () => {
        setView("restart");
    };

    return view === "restart" ? (
        <RestartBiddingDialog
            setView={setView}
        />
    ) : (
    <div className="App">
      <button type="button" onClick={exportPdf}>Skriv ut budförteckning</button>
      <button type="button" className="red" onClick={restartBidding}>Starta om budgivning</button>
    </div>
    );
}

export default App;
