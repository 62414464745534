import { jsPDF } from "jspdf";
import {DateTime} from "luxon";

class PdfApi {
    exportPdf(content) {
        const maxLength = 180;
        const startY = 30;
        const lineHeight = 6;

        const { bidders, bids, object, broker, coa, house} = content;

        const doc = new jsPDF();

        doc.setFontSize(12);

        let y = startY;

        const newSection = () => y += lineHeight*2;
        const newLine = () => {
            y += lineHeight;

            if (y > 270) {
                doc.addPage();
                addTemplate();
                y = startY;
            }
        };
        const addTemplate = () => {
            doc.setFillColor(76, 115, 92);
            doc.setTextColor(255, 255, 255);
            doc.rect(10, 10, 190, 7, "F");
            doc.setFont('helvetica', 'bold');
            doc.text("Budförteckning", 90, 15);

            doc.setTextColor(0, 0, 0);
            doc.setFont('helvetica', 'normal');
            doc.line(10, 285, 200, 285);
            doc.setFontSize(10);
            doc.text("Copyright © 2022 Privatmäklaren Skandinavien AB", 10, 290);
        };
        const formatAmount = n => n.toLocaleString("sv")+" kr";

        addTemplate();



        doc.setTextColor(0, 0, 0);
        doc.setFont('helvetica', 'bold');
        doc.text("Utskriftsdatum", 10, y);
        doc.setFont('helvetica', 'normal');
        doc.text(DateTime.now().toFormat("yyyy-MM-dd"), 60, y);

        newSection();
        doc.setFont('helvetica', 'bold');
        doc.text("Objekt", 10, y);
        doc.setFont('helvetica', 'normal');

        let introText;

        if (object.TypeID === 0) {
            // Apartment
            introText = `Bostadsrätten till lägenhet nr ${coa.ApartmentNumber} med adressen ${object.Address}, ${object.PostalCode} ${object.PostalAddress} i bostadsrättsföreningen ${coa.AssociationName}.`;
        } else {
            // House
            introText = `Fastigheten med beteckning ${house.FastighetsbeteckningLM} och adressen ${object.Address}, ${object.PostalCode} ${object.PostalAddress}.`;
        }

        let introTextLines = doc.splitTextToSize(introText, 135);
        introTextLines.map(t => {
            doc.text(t, 60, y);
            newLine();
        });

        if (broker) {
            newLine();
            doc.setFont('helvetica', 'bold');
            doc.text("Ansvarig mäklare", 10, y);
            doc.setFont('helvetica', 'normal');
            doc.text(`${broker.strBrokerName}, tel ${broker.strBrokerMobile}, e-post: ${broker.strBrokerEmail}.`, 60, y);
        }

        newLine();
        doc.setFont('helvetica', 'bold');
         doc.text("Budlista", 10, y);

        doc.text("Tidpunkt", 60, y);
        doc.text("Budgivare", 105, y);
        doc.text("Bud", 135, y);
        doc.text("Not", 165, y);

        let terms = [];
        let currentTermNumber = 0;
        let addTerms = text => {
            let existingTerm = terms.find(t => t.text === text);

            if (existingTerm) {
                return existingTerm.number;
            } else {
                currentTermNumber++;
                terms.push({
                    number: currentTermNumber,
                    text
                });
                return currentTermNumber;
            }
        }

        bids.forEach(b => {
            newLine();
            doc.setFont('helvetica', 'normal');
            doc.text(DateTime.fromJSDate(new Date(b.dtPlacedAt)).toFormat("yyyy-MM-dd kl HH:mm"), 60, y);
            doc.text("1", 105, y);
            doc.text(formatAmount(b.nAmount), 135, y);
            let termNumbers = "";

            // Other terms
            if (b.sOtherTerms.length > 0) {
                termNumbers += " "+addTerms(b.sOtherTerms);
            }

            // Private bid
            if (b.bPrivateByBidder || b.bPrivateBySeller) {
                termNumbers += " "+addTerms("Dolt bud");
            }

            // Withdrawn
            if (b.nBidStatusId === 2) {
                termNumbers += " "+addTerms("Återkallat");
            }

            doc.text(termNumbers, 165, y);
        });

        newSection();
        doc.setFont('helvetica', 'bold');
        doc.text("Budgivare", 10, y);

        newLine();
        doc.text("Nr", 60, y);
        doc.text("Name", 70, y);
        doc.text("E-post", 120, y);
        doc.text("Telefon", 160, y);
        newLine();

        bidders.forEach(b => {
            doc.setFont('helvetica', 'normal');
            doc.text(''+b.nBidderNumber, 60, y);
            doc.text(`${b.sFirstName} ${b.sLastName}`, 70, y);
            doc.text(b.sEmailAddress, 120, y);
            doc.text(b.sMobilePhoneNumber, 160, y);
            newLine();
        });

        if (terms.length > 0) {
            newLine();
            doc.setFont('helvetica', 'bold');
            doc.text("Villkor", 10, y);
            doc.setFont('helvetica', 'normal');

            terms.map(t => {
                doc.text(''+t.number, 60, y);
                doc.text(t.text, 70, y);
                newLine();
            })
        }

        newSection();
        doc.text("Denna budförteckning är upprättad i tre exemplar, en till säljare, en till köpare och en till mäklaren,", 10, y);
        newLine();
        doc.text("varav undertecknad tagit ett exemplar.", 10, y);

        doc.save("Document.pdf");
    }
}

export default PdfApi;