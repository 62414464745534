import "./RestartBiddingDialog.css";

const RestartBiddingDialog = props => {
    const cancel = () => {
        props.setView("default");
    }

    return (
        <div className="restartBidding">
            <p>Denna funktion raderar all information om budgivningen för detta objekt.</p>
            <p>Denna åtgärd kan inte ångras, var därför säker på vad du gör.</p>
            <p>Skriv in "Starta om budgivningen för objektet" i fältet nedan för att fortsätta.</p>
            <form>
                <input type="text" name="confirmationText" placeholder="Starta om budgivningen för objektet"/>
                <button className="red">Starta om budgivningen</button>
            </form>
            <button type="button" onClick={cancel}>Avbryt</button>
        </div>
    );
}

export default RestartBiddingDialog;